<template>
  <div class="wrapper">
    <va-navibar :hasMenu="false"></va-navibar>
    <va-slider :slideMenuItems="[]"></va-slider>
    <div id="content-wrap" class="content-wrapper pt-1">
      <h4 class="text-center">请选择要进入的客栈</h4>
      <div class="content" style="max-width:600px;margin:auto">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="text-nowrap" scope="col">#</th>
                <th class="text-nowrap" scope="col">名称</th>
                <th class="text-nowrap" scope="col">店主</th>
                <th class="text-nowrap" scope="col">状态</th>
              </tr>
            </thead>
            <tbody v-if="loading">
              <tr>
                <th colspan="4">
                  <i class="fas fa-spinner fa-pulse"></i> 请稍候……
                </th>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="i in inns">
                <th class="text-nowrap" scope="row">{{i.code}}</th>
                <td class="text-nowrap text-bold"><router-link :to="{ name: 'InnHome',params: { innId: i.id }}">{{i.title}}</router-link></td>
                <td class="text-nowrap"><span v-if="i.user">{{i.user.nickName}}</span></td>
                <td class="text-nowrap">{{i.workState}}</td>
              </tr>
            </tbody>
            <!--<tfoot>
                <tr>
                    <td colspan="4">
                    </td>
                </tr>
            </tfoot>-->
          </table>
        </div>
        <paging :page-index="pager.pageIndex"
                :total="pager.totalRecords"
                :page-size="pager.pageSize"
                :per-pages="3"
                :show-go="true"
                :prePageSize="pager.prePageSize"
                :onPagerChange="pageChange">
        </paging>
      </div>
    </div>
    <footer class="main-footer text-sm">
      <div class="float-right d-none d-sm-block">
        <b>Version</b> {{config.version}}
      </div>
      <strong>Copyright &copy; 2015-2022 <a href="https://panorover.com">PanoRover.com</a>.</strong> All rights reserved. <span class="d-inline d-sm-none"><b>Version</b> {{config.version}}</span>
    </footer>
    <aside class="control-sidebar control-sidebar-dark">
      <div class="control-sidebar-content p-3">
        <nav v-if="setting" class="nav flex-column">
          <a class="nav-link" :href="setting.myUrl">会员中心</a>
          <a v-if="$inRole('管理员')" class="nav-link" :href="setting.adminUrl">管理中心</a>
          <!--<a v-if="$inRole('管理员')||$inRole('客栈店主')||$inRole('客栈店员')" class="nav-link" :href="setting.innAdminUrl+'/Inn'">客栈管理</a>-->
          <a v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')" class="nav-link" :href="setting.innAdminUrl+'/Agent'">代理管理</a>
          <a v-if="$inRole('管理员')||$inRole('公共管理')" class="nav-link" :href="setting.myUrl+'/Public'">公共管理</a>
          <a v-if="$inRole('管理员')||$inRole('用户组管理员')||$inRole('用户组成员')" class="nav-link" :href="setting.myUrl+'/Group'">项目管理</a>
        </nav>
      </div>
    </aside>
  </div>
</template>

<script>
  import config from '@/../package.json'
  import { mapActions, mapGetters } from 'vuex'
  import VANaviBar from '@/components/NaviBar.vue'
  import VASlider from '@/components/Slider.vue'

  export default {
    components: {
      'va-navibar': VANaviBar,
      'va-slider': VASlider,
    },
    data() {
      return {
        inns: [],
        pager: {
          prePageSize: [10, 20, 50, 100],
          pageIndex: 1,
          pageSize: 10,
          totalPages: 0,
          totalRecords: 0
        },
        query: {
          pageIndex: 1,
          pageSize: 10,
          sort: 'AddTime',
          order: 'desc',
          fields: 'Title',
          key: null,
        },
        loading: false,
        config:config,
      }
    },
    methods: {
      ...mapActions([
        //'getNoticesCount',
        //'getNotices',
        //'getMessagesCount',
        //'getMessages',
        'getSetting',
        //'getUser'
      ]),
      loadData: function () {
        this.loading = true
        var config = {
          params: {
            page: this.query.pageIndex,
            pageSize: this.query.pageSize,
            sort: this.query.sort,
            order: this.query.order,
            fields: this.query.fields,
            key: this.query.key,
            agentId: this.$route.params.agentId
          }
        };
        var url = window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/InnAdmin/Inns` : `/Api/InnAdmin/Inns`;
        this.$axios.get(url, config).then((res) => {
          if (res.data.data.length == 0) {
            this.$router.push({ name: 'NoAccess' })
            return
          } else if (res.data.data.length == 1 && this.query.pageIndex == 1) {
            this.rotuerTo(res.data.data[0])
            return
          }
          this.inns = res.data.data
          this.pager.pageIndex = res.data.pageIndex
          this.pager.pageSize = res.data.pageSize
          this.pager.totalPages = res.data.totalPages
          this.pager.totalRecords = res.data.totalRecords
          this.loading = false
        }).catch((error) => {
          console.log(error)
          switch (error.status) {
            case 401:
              this.$router.push({ name: 'NoAccess' })
              break;
            case 403:
              this.$router.push({ name: 'NoAccess' })
              break;
          }
          //this.$router.push({ name: 'NoAccess' })
          this.loading = false
        })
      },
      pageChange(pageIndex, pageSize) {
        this.query.pageIndex = pageIndex;
        this.query.pageSize = pageSize;
        this.loadData();
      },
      rotuerTo(data) {
        this.$router.push({ name: 'InnHome', params: { innId: data.id } })
      },
    },
    created: function () {
      if (this.$inRole('管理员') || this.$inRole('客栈代理') || this.$inRole('客栈代理员工') || this.$inRole('客栈编辑') || this.$inRole('客栈店主') || this.$inRole('客栈店员')) {
        this.loadData();
      } else {
        this.$router.push({ name: 'NoAccess' })
      }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    computed: {
      ...mapGetters([
        'setting',
        //'unreadMessagesCount',
        //'unreadNotificationsCount',
        //'remainTasksCount',
        //'currentUser'
      ]),
    },
  }
</script>

<style>
</style>
